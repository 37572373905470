.mitra-button {
    width: 95px;
    height: calc(2.5em + 1px);
    color: white;
    border-radius: 0.25rem;
    outline: none;
    font-weight: 600;
    font-size: 13px;
    background-color: #5867dd;
    border: 1px solid #5867dd;
    margin-right: 1.5em;
}

.dropdown-toggle .btn .btn-success {
    height: calc(2.5em + 1px);
    padding: 0 1rem;
}

.mitra-button:hover {
    background-color: #384ad7;
    border-color: #293ccc;
}

.mitra-button:active {
    background-color: #2e40d4;
    border-color: #293ccc;
}

.mitra-button:disabled:hover {
    background-color: #a8acad;
    border-color: #a8acad;
}

.mitra-button:disabled {
    background-color: #a8acad;
    border-color: #a8acad;
}

.mitra-button-login {
    margin: unset;
    background-color: #8e1b3d;
    border: 1px solid #8e1b3d;
}

.mitra-button-login:hover {
    background-color: #6e1b37;
    border-color: #471424;
}

.mitra-button-login:active {
    background-color: #58172c;
    border-color: #471424;
}

.mitra-button-submit {
    background-color: #4dbc87;
    border: 1px solid #4dbc87;
}

.mitra-button-submit:hover {
    background-color: #3d976d;
    border-color: #077e5b;
}

.mitra-button-submit:active {
    background-color: #388b64;
    border-color: #077e5b;
}

.mitra-button-cancel {
    background-color: #d21938;
    border: 1px solid #d21938;
}

.mitra-button-cancel:hover {
    background-color: #a41938;
    border-color: #d21938;
}

.mitra-button-cancel:active {
    background-color: #83182e;
    border-color: #d21938;
}
