
.monitoring-table {
    /*margin: 10px;*/
    border-collapse: collapse;

}

.monitoring-table-td {
    /*height: 62px;*/
    /*width: 62px;*/
    padding: 15px;
    font-family: Heebo, sans-serif;
    font-weight: 600;
    color: #1a46da;
    font-size: 15px;

    /*border: 1px solid #646c9a;*/
}

.container-fluid-monitoring {
    margin: 50px 0 0 0;
    display: flex;
    flex-direction: column;
    min-width: 100%;
    max-width: 100%;
    border-top: 2px solid #646c9a;
}

.table-monitoring {
    display: table;
    border-collapse: collapse;

}

.row-monitoring {

    border-left: 1px solid #646c9a;
    border-top: 1px solid #646c9a;
    display: table-row;
    border-collapse: collapse;
}

.cell {
    float: left;
    border-collapse: collapse;
    display: table-cell;
    /*height: 62px;*/
    /*width: 62px;*/
    padding: 15px;
    font-family: Heebo, sans-serif;
    font-weight: 600;
    color: #1a46da;
    font-size: 15px;

    border-right: 1px solid #646c9a;
    border-bottom: 1px solid #646c9a;

    /*border: 1px solid #646c9a;*/
}

.monitoring-legend-ok {
    margin: 0 0.5em 0 0;
    padding: 1.1em;
    border: 1px solid #646c9a;
    background-color: #ffffff;
}

.monitoring-legend-noPayments {
    margin: 0 0.5em 0 0;
    border: 1px solid #646c9a;
    padding: 1.1em;
    background-color: #ffe21c;
    }

.monitoring-legend-oneFalied {
    margin: 0 5px 0 10px;
    background-color: #ff6011;
    padding: 14px;
    border: 1px solid #646c9a;
}

.monitoring-legend-allFailed {
    margin: 0 0.5em 0 0;
    padding: 1.1em;
    background-color: red;
    border: 1px solid #646c9a;
}

.monitoring-legend-offline {
    margin: 0 0.5em 0 0;
    padding: 1.1em;
    background-color: #000000;
    border: 1px solid #646c9a;
}

.monitoring-ok {
    background-color: #ffffff;
    /*border-style: solid;*/
    /*border-width: 1px  1px 1px 1px;*/
    /*border-color:#646c9a;*/

}

.monitoring-noPayments {
    background-color: #ffe21c;
    /*border-style: solid;*/
    /*border-width: 1px  1px 1px 1px;*/
    /*border-color:#646c9a;*/
}

.monitoring-oneFalied {
    background-color: #ff6011;
    /*border-style: solid;*/
    /*border-width: 1px  1px 1px 1px;*/
    /*border-color:#646c9a;*/
}

.monitoring-allFailed {
    background-color: red;
    /*border-style: solid;*/
    /*border-width: 1px  1px 1px 1px;*/
    /*border-color:#646c9a;*/
}

.monitoring-offline {

    background-color: #000000;
    /*border-style: solid;*/
    /*border-width: 1px  1px 1px 1px;*/
    /*border-color:#646c9a;*/
    /*color: #1a46da;*/
}

.monitoring-legend-text {
    vertical-align: central;
    color: #ffffff;
    font-weight: 600;
    padding: 7px 0 0 0;

}

.monitoring-legend-bloc {
    display: flex;
    margin-right: 4em;
    padding-left: 2em;
}

.row-monitoring-table {
    margin: 15px;
}

.container {
    padding: unset;
    margin: unset;
}

.dima-monitoring-body {
    padding: 0 0 0 0;
}

.label-team-monitoring {
    padding: 50px 0 9px 50px;
    vertical-align: central;
    font-weight: 600;
    font-size: 14px;
}

.mitra-table-monitoring {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
}

.mitra-table-monitoring-header-row {
    padding: 30px 50px;
    border-bottom: 2px solid;
    font-size: 1.2rem;
    font-weight: 600;
}


.mitra-monitoring-legend{list-style: none;
    width: 100%;
    /* padding: 3em 0 0 0; */
    height: 8em;
    align-items: center;
    display: flex;
    background-color: #9899ac;
}
