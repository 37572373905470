
.container {
    font-size: 19px;
    height: 82px;
    width: 100%;
    background-color: #ffffff;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    position: relative;
}

.control {
    overflow: hidden;
    width: 100%;
    height: 100%;
    border: 0.3rem solid black;
    border-radius: 5px;
    display: flex;
    align-items: center;
    padding: 5px 10px;
}

.controlFocus {
    border: 0.3rem solid #9aabff;
}

.valueContainer {
    display: flex;
    flex: 1 1 0;
    overflow: hidden;
}


.input {
    width: 100%;
    font-size: 16px;
}

.controlPanel {
    position: relative;
    -webkit-box-align: center;
    align-items: center;
    align-self: stretch;
    display: flex;
    flex-shrink: 0;
    box-sizing: border-box;
}

.controlPanel span {
    align-self: stretch;
    background-color: black;
    margin-bottom: 8px;
    margin-top: 8px;
    width: 1px;
    box-sizing: border-box;
}

.iconContainer {
    padding: 10px;
    cursor: pointer;
    transition: transform 150ms;
}

.iconContainer:hover {
    color: white;
}

.open {
    transform: rotate(180deg);
}

.placeholder {
    color: white;
}
/*OPTIONS=============================================================================================================*/

.options {
    z-index: 99;
    display: flex;
    flex-direction: column;
    max-height: 250px;
    min-height: fit-content;
    position: absolute;
    top: 82px;
    background-color: #ffffff;
    width: 100%;
    color: #282a3c;
    font-size: 19px;
    font-weight: 900;
    overflow-y: scroll;
    border: 1px solid #9aabff;
    border-radius: 5px;
    transition: position 5000ms;
}
.close{
    display: none;
}
.optionsItem {
    padding: 10px;
    transition: background-color 70ms;

}

.optionsItem:hover {
    cursor: pointer;
    background-color: #B0BFFF;

}
.optionsItemTarget {
    background-color: #B0BFFF;
}
/*OPTIONS=============================================================================================================*/
/*INPUT===============================================================================================================*/

.multiValue {
    color: #282a3c;
    display: flex;
    flex: none;
    align-items: center;
    background-color: #B0BFFF;
    font-size: 19px;
    font-weight: 900;
    margin-right: 8px;
    border-radius: 2px;
}

.multiValueText {
    padding: 1px 6px;
}

.multiValueIcon {
    cursor: pointer;
    padding: 5px 8px;
    color:#282a3c ;
}

.placeholder{
    font-size: 19px;
    font-weight: 900;
    color: #6c7293;
}
/*INPUT===============================================================================================================*/