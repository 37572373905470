.incass_cell_1 {
    width: 14em;
}

.incass_cell_2 {
    width: 9em;
}

.incass_cell_3 {
    width: 25em;
}

.incass_cell_4_head {
    width: 7em;
    padding-left: 5px;
    /*text-align: right;*/
}
.incass_cell_4 {
    width: 8em;
    text-align: right;
}

.incass_cell_5 {
    width: 6em;
    text-align: right;
}

.incass_cell_6 {
    width: 6em;
    text-align: right;
}

.incass_cell_7 {
    width: 6em;
    text-align: right;
}

.incass_cell_8 {
    width: 6em;
    text-align: right;
}

.incass_cell_9 {
    width: 6em;
    text-align: right;
}

.incass_cell_10 {
    width: 6em;
    text-align: right;
}

.incass_cell_11 {
    width: 6em;
    text-align: right;
}
