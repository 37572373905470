
ul {
    list-style: none;
}

@media (min-width: 1px) and (max-width: 748px) {
    .nav_incass {
        margin-top: -50px;
        /*width: fit-content!important;*/
    }
}

@media (min-width: 749px) and (max-width: 1024px) {
    .nav_incass {
        margin-top: -50px;
    }
}

.nav_incass {
    width: 100%;
    background-color: #0f0f16;
}

.nav_incass > ul {
    display: -o-flex;
    display: -moz-flex;
    display: -webkit-flex;
    display: -ms-flex;
    display: flex;
    justify-content: center;
    color: #ffffff;
    font-family: Poppins, sans-serif;
    font-size: 2.5em;
    font-weight: 600;
    margin: unset;
    padding: unset;
}

.nav_incass > ul > li {
    padding: 1em 1.5em;
}

.mitra-link-incass {
    color: #ffffff;
}

.mitra-link-incass:hover {
    color: #ffffff;
}

.mitra-link-nav {
    background-color: #dedede;
}

.mitra-link-nav-text:hover {
    color: #0f0f16;
}.mitra-link-nav-text{
    color: #0f0f16;
}