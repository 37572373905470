.modal-header {
    border-bottom: unset;
}
.mitra-h4-modal{
    text-align: center;
}
.modal-footer {
    margin-right: 12.6rem;
    border-top: unset;
}
.mitra-paragraph-modal {
    text-align: center;
    /*margin-left: 14rem;*/
    margin-top: 3rem;
    margin-bottom: 1rem;
    font-size: 1.4rem;
    font-weight: 500;
}