.body_example {
    display: flex;
    flex-direction: column;
    padding: 20px;
    background-color: #f1f2f7;
}

.mitra-raw {
    margin-top: 15px;
    display: flex;
    flex-direction: row;
    width: 100%;
}

.mitra-block-2 {
    display: flex;
    flex-direction: column;
    background-color: #ffffff;
    padding: 15px 0;
    margin-right: auto;
    margin-left: auto;
    max-width: 49%;
    min-width: 49%;
    box-shadow: 0 0 13px 0 rgba(82, 63, 105, .05);
    border-radius: 4px;
}

.example_block {
    display: flex;
    flex-direction: column;
    background-color: #ffffff;
    padding: 15px 0;
    margin-right: auto;
    margin-left: auto;
    max-width: 99%;
    min-width: 99%;
    box-shadow: 0 0 13px 0 rgba(82, 63, 105, .05);
    border-radius: 4px;
}

.example_block_head {
    border-bottom: 1px solid #ebedf2;
    width: 100%;
    font-size: 15px;
    font-weight: bold;
    height: 25px;
    min-height: 60px;
}

.example_block_body {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.example_block_raw {
    display: flex;
    flex-direction: row;
    margin: 15px 20px;
}

.example_cell {
    display: flex;
    min-width: 42%;
}

.mitra-paragraph {
    margin-left: 20px;
    font-family: Poppins, sans-serif;
    font-size: 13px;
    font-weight: 600;
}



.mitra-select {
    max-width: 100%;
    min-width: 100%;
    height: calc(1.5em + 1em + 1px);
    border-radius: 4px;
}


.select2-container--default.select2-container--disabled .select2-selection--multiple, .select2-container--default.select2-container--disabled .select2-selection--single {
    cursor: default;
    background: transparent;
    border-color: transparent;
}

.select2-container--default .select2-selection--single .select2-selection__arrow:before, .select2-container--default .select2-selection--multiple .select2-selection__arrow:before {
    content: unset;
}

.mitra-table {
    font-family: Heebo, sans-serif;
    display: block;
    border-collapse: collapse;
    overflow: hidden;
    width: 100%;
}

.mitra-table-head {
    display: block;
    position: relative;
}

.mitra-table-head-raw {
    border-bottom: 3px solid #646c9a;
    width: 100%;
    display: table;
    table-layout: initial;
}

.mitra-table-cell {
    vertical-align: middle;
    font-size: 1rem;
    padding: 16px 10px;
}

.mitra-icon-table-head {
    margin-left: 10px;
    color: #5d78ff;
    vertical-align: middle;
    font-size: 8px;
}

.mitra-table-body {
    display: block;
    position: relative;
}

.mitra-table-body-raw {
    border-bottom: 1px solid #c8cbd6;
    width: 100%;
    display: table;
    table-layout: initial;
}

.mitra-table-body-raw-error {
    border-bottom: 1px solid #c8cbd6;
    color: #ffffff;
    background-color: #ed2601;
    width: 100%;
    display: table;
    table-layout: initial;
}

.mitra-extended-button {
    background-color: transparent;
    border: unset;
    color: #646c9a;
}

.mitra-table-monitoring-example {
    width: 200px;
}

.mitra-table-monitoring-row {
    display: flex;
    flex-wrap: wrap;
    border-top: 1px solid #646c9a;
    border-left: 1px solid #646c9a;
}

.mitra-table-monitoring-cell {
    padding: unset;
    color: #1a46da;
    border-right: 1px solid #646c9a;
    border-bottom: 1px solid #646c9a;
}

.mitra-button-monitoring {
    color: #1a46da;
    font-size: 15px;
    font-family: Heebo, sans-serif;
    font-weight: 900 !important;
    border: unset;
    height: 65px;
    width: 65px;
}

.mitra-popover-content-list {
    list-style: none;
    padding: 0;
    color: #000000;
    font-size: 13px;
    font-weight: 600;
}

.mitra-popover-title{
    text-align: center;
    color: #000000;
    font-size: 2.5rem;
    margin: unset;
}
.mitra-paragraph-heebo{
    font-family: Heebo, sans-serif;
    color: #646c9a;
    font-size: 1rem;
    font-weight: 600;
}
.mitra-paragraph-poppins{
    font-family: Poppins, sans-serif;
    color: #646c9a;
    font-size: 1rem;
    font-weight: 600;
}