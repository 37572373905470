.kt-portlet_settings {
    margin-bottom: 0;
    padding: 30px 0 30px 50px;
}
.settings_bloc{
    border-bottom: 2px solid #646c9a;
    /*border-bottom: 1px solid #c8cbd6;*/
    width: 100%;
    margin-bottom: 25px;
    padding-bottom: 20px;
}
.switch_table_dima{
    width: 25%;
}

.btn_save_limit{
margin: unset;
}
.switch_block_dima_settings_btn{
    padding-top: 10px;
}

.switch_block_dima_settings{
    max-width: 100%;
    min-width: 100%;
    display: flex;
}
.settings_bloc_bottom{
    display: flex;
    flex-direction: column;
    /* width: 100%; */
    margin-bottom: 25px;
    padding-bottom: 20px;
}
.label_inline_switch_country{
    padding-left: 15px;
}
.label_inline_switch_settings{
    font-size: 13px;
    font-weight: 900;
    padding: 5px 10px 5px 15px;

}
.dima_form_control_settings{
    font-size: 13px;
    font-family: Poppins, sans-serif;
    font-weight: 600;
    width: 55px;
    padding-left: 14px;
    height: calc(1.5em + 1em + 1px);
    border: 1px solid #e2e5ec;
}
.dima_form_control_settings:focus{
  outline: none;
}
.label_form_switch_country{
    overflow: hidden;
    white-space: nowrap;
    max-width: 100%;
    min-width: 100%;
}

.limit_operator{
    display: flex;
    width: 95px;
    margin-right: 15px;
}

.col-lg-4{
    padding-left: unset;
}
.cell_settings{
}
.cell_settings_international{
display: flex;
}
.dima_country{
    display: flex;
}
.label_settings{
    width: 173px;
}
.paragraf_settings{
    padding-bottom: 15px;
    font-size: 24px;
    font-weight: 900;
}
.cell_settings_payments{
    display: flex;
}