.mitra-input {
    max-width: 100%;
    min-width: 100%;
    outline: none;
    border: 1px solid #e2e5ec;
    height: calc(1.5em + 1em + 1px);
    border-radius: 4px;
    padding-left: 14px;
    color: #000000;
    font-weight: 600;
    font-size: 1rem;
}

.mitra-input:disabled {
    color: #646c9a;
    font-weight: 600;
    font-size: 1em;
}

.mitra-input::placeholder {
    color: rgba(108, 114, 147, 0.86) !important;
}

.mitra-input:focus {
    border-color: #9aabff
}