.check_cell_1 {
    width: 15em;
}

.check_cell_2 {
    width: 11em;
}

.check_cell_3 {
    width: 10em;
}

.check_cell_4 {
    width: 10em;
}

.check_cell_5 {
    width: 7em;
    text-align: right;
}

.check_cell_6 {
    width: 7em;
    text-align: right;
    margin-right: 2em;
}

.check_cell_7 {
    width: 12em;
}

.check_cell_8 {
    text-align: right;
    width: 6em;
}
