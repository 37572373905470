
.mitra_row-check{
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    margin: 2em 0;
    justify-content: center;
    width: 100%;
    flex-direction: column;
}
.mitra-check-li-h1{
    font-size: 1.9em;
    font-family: Poppins, sans-serif;
    font-weight: 900 !important;
    margin: 0 0 1.5em 0;
}
.mitra-check-ul{
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    width: fit-content;
    width: -moz-fit-content;
    flex-direction: column;
    list-style: none;
    margin: auto;
    padding: unset;
    color:#0f0f16;
}
.mitra-check-li{
    border-bottom: 1px solid #0f0f16;
    font-weight: 600;
    font-size: 1.2em;
    display: flex;
    align-items: center;
    height: 2.7em;
}
.mitra-check-label{
    width: 6em;
    display: flex;
    align-items: center;
    height: 2.7em;
    margin: unset;
}
.mitra-check-label-respounce{
    width: 6em;
    display: flex;
    align-items: center;
    height: 2.7em;
    margin: unset;
    font-size: 1.2em;
    font-weight: 600;
}
.mitra-check-li-respounce{
    /*display: flex;*/
    height: unset;
    /*align-items: start;*/
}
.mitra-check-textarea{
    width: 500px;
    outline: unset;
    font-weight: 600;
    font-size: 1.2em;
    margin-top: 1em;
    border-radius: 0.2em;
    border: 0.1rem solid black;
}
.mitra-check-li-button{
    margin-top: 2em;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    justify-content: center;
}
.mitra-check-button{
    margin: 0 20px 0 0;
}
