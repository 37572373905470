.mitra_row-check{
    display: flex;
    display: -moz-flex;
    display: -webkit-flex;
    display: -ms-flex;
    display: -o-flex;
    justify-content: center;
    padding-top: 2em;
}
.mitra-link{
    color: #0f0f16;
}
.mitra-link:hover{
    color: #0f0f16;
}
.mitra-placeholder::placeholder{
    color: #6c7293 !important;
}
.select2-container--default .select2-search--inline .select2-search__field::placeholder {
   color: rgba(108, 114, 147, 0.86) !important;
}

.progress-bar-ncass{
    width: 100%;
    /*background-color: #ebedf2;*/
}