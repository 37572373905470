/*.btn_form-dima{*/
/*    padding-left: 20px;*/
/*    padding-top: 20px;*/
/*}*/
.kt-portlet .kt-portlet__body {
    background-color: #ffffff;
    border: unset;
    padding-left: 50px;
    padding-top: 35px;
}

.dima_form_full {
    display: flex;
}

.label_full {
    font-size: 13px;
    font-weight: 900;
    width: 125px;
    padding-top: 10px;
}
.mitra-invalid{
    border-color: red;
}
.dima_form_control {
    width: 425px;
}

.btn_form-dima_terminal {
    padding: 0 0 50px 50px;

}

.response_textarea {
    font-size: 1rem;
    height: auto;
    font-weight: 600;
    width: 440px;
    margin-top: 4px;
    margin-bottom: 4px;

}

.currency_bloc_full {
    display: flex;
    margin-right: 10px;
}

.kt-portlet__body_form_full {
    padding-top: unset;
}

.currency_bloc_right_full {
    display: flex;
}

.input_form_full_currency_select {
    margin-left: 10px;

}

.input_form_full_currency {
    width: 76px;
}

.input_bloc_full {
    border-bottom: 1px solid #C8CBD6;
    padding-bottom: 10px;
    width: 700px;
}

.input_left_full {
    margin-right: 20px;
    width: 220px;
}

.input_center_full {
    margin-right: 20px;
    width: 220px;
}

.input_right_full {
    width: 220px;
}

.input_full {
    margin-right: 20px;
    width: 400px;
}
.dima_form_single_switch{
    width: 555px;
    display: flex;
    /*border-bottom: 1px solid #C8CBD6;*/
    padding-top: unset;
    padding-bottom: unset;
    align-items: center;
    height: 3.5em;
}

.dima_form_single {
    width: 555px;
    display: flex;
    border-bottom: 1px solid #C8CBD6;
    padding-top: unset;
    padding-bottom: unset;
    align-items: center;
    height: 3.5em;
}

.p_form {
    font-size: 13px;
    font-weight: 900;
    width: 125px;
    padding-top: 10px;
}

.input_form_single_select_currency {
    width: 83px;
    margin-top: 2px;
    margin-left: 10px;
}

.input_form_single_currency {
    width: 357px;
    padding-right: 10px;
    /*margin-bottom: 5px;*/
    /*padding-top: 5px;*/
}

.input_form_single_currency_p {
    width: 440px;
}

.dima_input_got {
    width: 80px;
    /*text-align: right;*/
}

.dima_input_got:disabled{
    width: 8px;
    padding: unset;
    margin: unset;
}


.dima_form_single_currency {
    width: 555px;
    display: inline-flex;
    border-bottom: 1px solid #C8CBD6;
    /* margin-bottom: 10px; */
    padding-top: 5px;
    padding-bottom: 5px;
    align-items: center;
    height: 45px

}

.dima_form_single_textarea {
    width: 555px;
    /*height: 120px;*/
    display: inline-flex;
    /*margin-bottom: 10px;*/
    border-bottom: 1px solid #C8CBD6;
}

.input_form_single_textarea {
    width: 420px;
    margin-right: 20px;
}

.btn_form-dima {
    display: flex;
    margin: 3em 0 0 0;
}

textarea:disabled {
    background-color: #f7f8fa;
}

.btn_dima {
    margin-left: 20px;
    width: 95px;
    height: calc(2.5em + 1px);
    /*background-color: #d21938;*/
}

.btn_dima:disabled {
    margin-left: 20px;
    width: 95px;
    height: calc(2.5em + 1px);
    background-color: #798081;
    border: 1px solid #798081;
    /*background-color: #d21938;*/
}

.btn-primary.disabled, .btn-primary:disabled {
    /*background-color: #6b78de;*/
}

.btn_tab_dima {
    width: 570px;
    display: inline-flex;
    border-bottom: 3px solid;
    /* margin-bottom: 10px; */
    padding-bottom: 10px;
    padding-top: 10px;
    margin-left: 20px;
}

.dima_input_got_new_form {
    width: 330px;
}

.dima_input_form_single_select_currency {
    padding-left: 5px;
    width: 100px;
}

.dima_form_btn_extended {
    border-bottom: 1px solid #C8CBD6;
    width: 565px;
    padding-top: 5px;
    padding-bottom: 13px;
}

.select2-container--default .select2-selection--single .select2-selection__rendered {
    color: #000000;
    padding-top: 6px;
    padding-left: 14px;
    font-weight: 600;
    font-size: 1em;
}
.select2-container--disabled .select2-selection--single .select2-selection__rendered {
    color: #646c9a;
    font-weight: 600;
    font-size: 1em;
}


.form-control {
    padding-left: 14px;
    color: #000000;
}

.form-control:focus {
    padding-left: 14px;
    color: #000000;
}

.label_form {
    width: 130px;
}

.col-lg-1 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 8.33333%;
    flex: 1 0 9.33333%;
    max-width: 93.33333%;
}

.input_form_single {
    /*margin-bottom: 5px;*/
    width: 435px;
}

.label_inline {
    font-size: 13px;
    font-weight: 900;
    /*width: 150px;*/
    padding-top: 5px;
    /* padding-bottom: 5px;*/
}

.label_form_switch {
    color: white;
    font-family: Heebo, sans-serif;
    font-weight: bolder;
    margin-left: 12px;
    margin-top: 5px;
    margin-right: 10px;
}

.dima_edit_currency {

}

.got_edit_block {
    display: flex;
}

.got_edit {
    width: 62px;
    margin-right: 5px;
}

.dima_form_p {
    font-family: Poppins, sans-serif;
    font-size: 13px;
    font-weight: 600;
    padding-top: 11px;
    /* padding-bottom: unset; */
    /* margin-bottom: 10px; */
    padding-left: 15px;
    width: 440px;
}

.switch_dima {
    display: flex;
}

.form_dima_line {
    border-spacing: 1px;
    border-color: #C8CBD6;
}

.extended_form_dima {
    display: inline-grid;
    /*margin-top: 30px;*/
}
.dima_form_single_extended{
    margin-top: 20px;
}
.extended_form_dima_terminal {
    width: 555px;
    /*padding-top: 10px;*/
    padding-top: 5px;
    padding-bottom: 5px;
    border-bottom: 1px solid #C8CBD6;
}

.btn_dima_extended {
    margin-left: unset;
    margin-right: 20px;
}

.dima_form_p_currency {
    padding-top: 11px;
}

.react-switch-handle {
    background: #ebedf2;
}

.dima_form_select_form {

}

.extended_form_dima_terminal_switch {
    /*display: flex;*/
    padding-top: 40px;
}

.dima_form_single_end {
    padding-top: 5px;
    padding-bottom: 5px;
    align-items: center;
    height: 45px;
    display: flex;
    border-bottom: 3px solid #646c9a;
    width: 555px;
}

.average_p {
    padding-top: 60px;
    font-size: 16px;
    font-weight: 900;
    padding-bottom: 10px;
}

.label_inline_switch {
    font-size: 13px;
    font-weight: 900;
    /*width: 150px;*/
    padding:5px 0 0 5px;

    /* padding-bottom: 5px;*/
}

.switch_block_dima {
    /*margin-right: 10px;*/
    display: flex;

}

.form_block_switch {
    display: flex;
    border-bottom: 1px solid #C8CBD6;
    width: 555px;
    padding-top: 7px;
    height: 46px;
}

.input_form_single_select {

}

.extendedForm {
    color: #646c9a;
    font-size: 13px;
    background-color: transparent;
    border: unset;
}

.extended_span {
font-weight: 900;;
}

.form_select {
border-color: #0f0f16;
padding-top: 6px;
}

.datepikcer {
width: 433px;
font-family: Heebo, sans-serif;
font-size: 13px;
font-weight: 900;
padding-left: 10px;
height: 35px;
border-radius: 5px;
border: 1px solid #e2e5ec;
}

.react-datepicker-ignore-onclickoutside {
width: 433px;
font-family: Heebo, sans-serif;
font-size: 13px;
font-weight: 900;
padding-left: 10px;
height: 35px;
border-radius: 5px;
border: 1px solid #e2e5ec;
}

/*Start DatePicker*/
.react-datepicker-ignore-onclickoutside {
    width: 433px;
    font-family: Heebo, sans-serif;
    font-size: 13px;
    font-weight: 900;
    padding-left: 10px;
    height: 35px;
    border-radius: 1px;
    border: 1px solid #333;
}

.react-datepicker-ignore-onclickoutside:focus {
    /*width: 433px;*/
    /*font-family: Heebo ,sans-serif;*/
    /*font-size: 13px;*/
    /*font-weight: 900;*/
    /*padding-left: 10px;*/
    /*height: 35px;*/
    border-radius: 1px;
    border: 1px solid #333;
}

/*End DatePicker*/