.mitra_row-dashboard{
    padding-top: 3em;
    display: flex;
    display: -o-flex;
    display: -ms-flex;
    display: -webkit-flex;
    display: -moz-flex;
    justify-content: center;
}
.mitra_item-dashboard{
    margin-right: 3em;
}