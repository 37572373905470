.monitoring-legend-text-incass {
    font-weight: 700;
    font-size: 1.5em;
    padding: 0.3em 0 0 0;
    color: #0f0f16;
}

.mitra-table-monitoring-header-row-incass {
    border-top: 0.35em solid #0f0f16;
    border-bottom: 0.35em solid #0f0f16;
    color: #0f0f16;
    font-size: 1.5rem;
    font-weight: 600;
}

.mitra-popover-content-list-incass {
    list-style: none;
    padding: 0;
    color: #000000;
    font-size: 1.5em;
    font-weight: 600;
}

.mitra-popover-title-incass {
    text-align: center;
    font-size: 4em;
    font-family: Heebo, sans-serif;
    font-weight: 900;
    color: black;
}

.mitra-table-monitoring-incass {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    /*margin: -39px 0 0 0;*/
}
.mitra-monitoring-legend-incass{list-style: none;
    width: 100%;
    /* padding: 3em 0 0 0; */
    height: 11.3em;
    align-items: center;
    display: flex;
    background-color: #dedede;
}
.mitra-button-monitoring-incass{
    font-size: 2em;
    color: #1a46da;
    font-family: Heebo, sans-serif;
    font-weight: 900;
    border: unset;
    height: 85px;
    width: 85px;
}
