.mitra_report{
 display: flex;
}
.mitra_report-button{
    margin-left: 1em;
}

.mitra_report_download{

}.select2-container--default .select2-selection--multiple .select2-selection__placeholder, .select2-container--default .select2-selection--single .select2-selection__placeholder {
     color: #8186a2;
     font-weight: 600;
     font-size: 1rem;
 }