
.mitra-table-statistic {
    width: 32em;
    font-family: Poppins, sans-serif;
    font-size: 1.35em;
    font-weight: 900;
    color: #0f0f16;
    border-radius: 0.2em;
    border-collapse: collapse;
    box-shadow: 0 0 0 0.3em #0f0f16;
}


.mitra-table-statistic > thead {
    font-size: 1.5em;
    color: #ffffff;
    background: #0f0f16;
}

.mitra-table-statistic > thead > tr > th {
    padding: 1em;
}

.mitra-table-statistic > tbody > tr {
    border-bottom: 0.1em solid #0f0f16;
}

.mitra-table-statistic > tbody > tr > td {
    font-size: 1.5em;
    padding: 1em;
}

.mitra-table-today-statistic {
    text-align: right;
}

.mitra-table-yesterday-statistic {
    text-align: right;
    background-color: #dedede;
}