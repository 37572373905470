.mitra_table_dima_head > tr {
    display: flex;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    border-bottom: 0.2em solid #646c9a;
    padding: 1.231em 1em;
    margin: auto;
}

.mitra_table_dima_head > tr > th {
    color: #6c7293;
    font-family: Poppins, sans-serif;
    font-size: 1em;
    font-weight: 900;
    margin-left: 1.5em;
}

.mitra_table_dima_head > tr > th > small {
    color: #0440ea;
    font-size: 0.7em;
}

.mitra_table_dima {
    display: flex;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    flex-direction: column;
    width: 100%;
}

.mitra_table_dima_body > tr {
    display: flex;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    border-bottom: 1px solid #646c9a;
    /*justify-content: space-between;*/
    margin: auto;
    padding: 0.7em 1em;
    height: 4em;
    align-items: center;
}

.mitra_table_dima_body_tr-error {
    display: flex;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    border-bottom: 1px solid #646c9a;
    background-color: #d22138;
    margin: auto;
    padding: 0.7em 1em;
    align-items: center;
}

.mitra_table_dima_body_tr-error > td {
    color: #ffffff!important;
}

.mitra_table_dima_body > tr > td {
    color: #6c7293;
    overflow: hidden;
    align-items: center;
    font-family: Heebo, sans-serif;
    font-size: 1em;
    font-weight: 600;
    margin-left: 1.5em;
}

.mitra_table_dima_tr_noFound {
    border: unset !important;
    justify-content: center !important;
    /*font-size: 3em !important;*/
    margin-top: 17em !important;
    /*font-family: Poppins !important;*/
}

.mitra_table_dima_td_noFound {
    font-family: Poppins, serif !important;
    justify-content: center !important;
    font-size: 20px !important;
    font-weight: 700 !important;
}