.mitra-body{
    display: flex;
    flex-direction: column;
}


.login{
    width: auto;
    margin:10% auto 25px;
}
.btn_login{
    margin-top: 25px;
    padding-left: 32%;
}
.block_input_login{
margin-top: 5px;
}
.massege_login{
    width: auto;
    margin: auto;
    /*padding-top: 15%;*/
    padding-bottom: 20px;
    color: red;
    font-size: 13px;
    font-family: Poppins, sans-serif;
    font-weight: 600;
}

.input_login{
    width: 260px;
    font-family: Poppins, sans-serif;
    font-size: 13px;
    border: 2px solid black

}
.input_login:focus {
    color: black;
    font-family: Poppins, sans-serif;
    font-size: 13px;
    border-color:  black

}
/*.input_login::-webkit-input-placeholder {*/
/*    font-family: Poppins, sans-serif;*/
/*    font-size: 13px;*/
/*    border: 2px solid black*/
/*}*/