.mitra_form {
    display: flex;
    display: -o-flex;
    display: -ms-flex;
    display: -webkit-flex;
    display: -moz-flex;
    justify-content: center;
    background-color: #dedede;
    padding: 3.5em 3.5em 2.5em 3.5em;
}

.mitra_form-check {
    display: flex;
    display: -o-flex;
    display: -ms-flex;
    display: -webkit-flex;
    display: -moz-flex;
    background-color: #dedede;
    padding: 3.5em 0;
    justify-content: center;
}

.mitra-form-row {
    margin: 0 1em 0 0;
}

.mitra-form-item {
    width: 350px;
    height: 90px;
    margin: 0 1em 1em 0;
}

.mitra-button-incass {
    font-family: Poppins, sans-serif;
    height: 4.2em;
    margin: unset;
    font-weight: bold !important;
    font-size: 1.5em;
}

.mitra-input-check {
    width: 20em;
    font-size: 30px;
    border: 0.3rem solid black;
    height: 2.7em;
}

.mitra-button-check-submit {
background-color: #00BB39;
border-color:#00BB39 ;
}

.mitra-button-check-submit:hover {
background-color: #00872b;

}

.mitra-button-check-submit:focus {
background-color: #00872b;

}

.mitra-input-check::placeholder {
color: rgba(0, 0, 0, 0.78) !important;
}

.mitra-input-check:focus {
border: 0.3rem solid black;
}

.mitra-form-item-check {
margin-right: 1em;
}
.mitra-input-check-date{
    width: 36em;
    font-size: 19px;
    font-weight: 900;
    border: 0.3rem solid black;
    height: 82px;
}