.terminal_cell_1 {
    width: 7em;
}

.terminal_cell_2 {
    width: 6em;
}

.terminal_cell_3 {
    width: 25em;
}

.terminal_cell_4 {
    position: relative;
    width: 20em;
    text-align: center;
}

.terminal_cell_5 {
    width: 6em;
}

.terminal_cell_6 {
    width: 10em;
}

.terminal_cell_7 {
    width: 6em;
}

.terminal_cell_8 {
    text-align: right;
    /*padding-right: 2.7em;*/
    width: 7em;
}
.terminal_cell_8_head {
    width: 7em;
}

.terminal_cell_9 {
    width: 12em;

}

.progress-bar{
    position: relative;
    /*float: left;*/
}
.progress-bar-title{
    position: absolute;
    left: 168px;
    font-weight: 900;
}
.kt-datatable__cell{
    position: relative;
}
.incassRole{
    left: 9em;
    right: 9em;
    font-size: 1.3em;
    font-weight: 900;
}
.incass-bar{
    height: 2em;
}