.cell_report{
 max-width: 170px;
 min-width: 170px;
}

.input_report{
 margin-left: 10px;
 max-width: 170px;
 min-width: 170px;
}
.cell_report_edit{
 margin-left: 10px;
 max-width: 60px;
 min-width: 60px;
}